<template>
  <div class="container">
    <div class="row" style="margin-top: 10px;">
      <div class="d-flex justify-content-start">
        <img src="../assets/logo.png" style="max-height: 60px;">
      </div>
    </div>
    <div class="row">
      <div class="d-flex justify-content-end">
        <router-link to="/login" class="btn btn-primary"><i class="fas fa-unlock" style="margin-right: 5px;"></i><span
            class="is-hidden-mobile">システムへの</span>ログイン<span class="">はこちら</span></router-link>
      </div>
    </div>
    <div class="row" style="padding: 30px;">
      <div class="col-5 d-none d-md-block ">
        <img src="../assets/blue.png" style="max-height: 250px;">
      </div>
      <div class="col-12 col-md-7 d-flex align-items-center">
        <div class="row flex-fill">
          <h1 class="service_name fs-1" style="color: #0097E0;">BLUE</h1>
          <h4 class="fs-3">音楽著作権再分配</h4>
          <button class="btn btn-light" disabled style="margin-top: 10px;">Coming soon...</button>
        </div>
      </div>
    </div>
    <div class="row" style="padding: 30px;">
      <div class="col-12 col-md-7 d-flex align-items-center">
        <div class="row flex-fill">
          <h1 class="service_name fs-1" style="color: #E83821;">RED</h1>
          <h4 class="fs-3">原盤分配</h4>
          <a href="https://www.rights.tokyo/service/red" target="_blank" class="btn text-white" disabled="disabled" style="margin-top: 10px; background-color: #E83821;">詳細を見る</a>
        </div>
      </div>
      <div class="col-5 d-none d-md-block ">
        <img src="../assets/red.png" style="max-height: 250px;">
      </div>
    </div>
    <div class="row" style="padding: 30px;">
      <div class="col-5 d-none d-md-block ">
        <img src="../assets/pink.png" style="max-height: 250px;">
      </div>
      <div class="col-12 col-md-7 d-flex align-items-center">
        <div class="row flex-fill">
          <h1 class="service_name fs-1" style="color: #EE86A8;">PINK</h1>
          <h4 class="fs-3">その他の印税管理</h4>
          <button class="btn btn-light" disabled style="margin-top: 10px;">Coming soon...</button>
        </div>
      </div>
    </div>
    <div class="row" style="padding: 30px;">
      <div class="col-12 col-md-7 d-flex align-items-center">
        <div class="row flex-fill">
          <h1 class="service_name fs-1" style="color: #8EC31F;">GREEN</h1>
          <h4 class="fs-3">オンライン明細</h4>
          <a href="https://www.rights.tokyo/service/green" target="_blank" class="btn text-white" disabled="disabled" style="margin-top: 10px; background-color: #8EC31F;">詳細を見る</a>
        </div>
      </div>
      <div class="col-5 d-none d-md-block ">
        <img src="../assets/green.png" style="max-height: 250px;">
      </div>
    </div>
    <footer class="alert alert-primary" style="padding-bottom: 0;">
      <div class="row" style="margin-bottom: 10px;">
        <img src="../assets/logo_corp.png" style="max-width: 300px;">
      </div>
      <div class="row" style="margin-left: 20px;">
        東京ライツサービス株式会社
      </div>
      <div class="row" style="margin-left: 20px;">
        〒151-0053 東京都渋谷区代々木1-30-14
      </div>
      <div class="row text-start" style="margin-left: 8px;">
        <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
      </div>
      <div class="row" style="margin-top: 15px;">
        <div class="alert alert-primary text-end" style="background-color: #0A58CA; color: white;">© 2025 Tokyo Rights Service Inc.</div>
      </div>
    </footer>
  </div>
</template>

<style scoped lang="scss">
.service_name {
  font-family: 'Sigmar One', sans-serif;
  font-weight: bold;
}
</style>
