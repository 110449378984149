<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/definition/list"><i class="fas fa-cabinet-filing fa-fw" style="margin-right: 5px;"></i> 取込ファイル定義一覧</router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item">
                    <router-link :to="'/red/definition/disp/' + form.id"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</router-link>
                  </li>
                  <li v-if="form && form.id" class="breadcrumb-item active" aria-current="page"><i class="fas fa-edit" style="margin-right: 5px;"></i>編集</li>
                  <li v-else class="breadcrumb-item active" aria-current="page"><i class="fas fa-plus" style="margin-right: 5px;"></i>追加</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="save" class="btn btn-primary" style="margin-left: 5px;"><i class="fas fa-save fa-fw" style="margin-right: 5px;"></i>保存</button>
              <button v-if="showDeleteButton" @click="openDeleteModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw" style="margin-right: 5px;"></i>削除</button>
              <button v-if="form && form.id" @click="showDeleteButton = !showDeleteButton" class="btn" style="padding-right: 0;"><i class="fas fa-caret-left"></i></button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="form-label align-bottom">
            <i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前
          </div>
          <div class="form-edit-line">
            <input v-model="form.name" type="text" class="form-control">
          </div>
          <div class="form-label align-bottom">
            <i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>データ開始行
          </div>
          <div class="form-edit-line">
            <input v-model="form.dataStartRow" type="number" class="form-control" style="width: 80px;">
          </div>
          <div class="form-label align-bottom">受領額</div>
          <div class="form-edit-line">
            <select v-model="form.incomeAmountType" class="form-select">
              <option value="data">データ内</option>
              <option value="data_percent_deduction">データ内の金額から割合控除</option>
              <option value="data_all_amount_deduction">データ内の金額から総控除額を使用数按分した額を控除</option>
              <option value="distribution">総受領額を使用数按分</option>
              <option value="distribution_percent_deduction">総受領額から割合控除後の金額を使用数按分</option>
              <option value="distribution_all_amount_deduction">総受領額から金額控除後の金額を使用数按分</option>
              <option value="unit_price">使用数 × 指定単価</option>
            </select>
          </div>
          <div class="form-label align-bottom">マッチング項目複数時の挙動</div>
          <div class="form-edit-line">
            <select v-model="form.multiMatchingType" class="form-select">
              <option value="or">いずれかとマッチング</option>
              <option value="one">一つのコードにしてマッチング</option>
              <option value="all">全てがマッチング</option>
            </select>
          </div>
          <div class="form-label align-bottom">利用年月指定箇所</div>
          <div class="form-edit-line">
            <select v-model="form.usedMonthType" class="form-select">
              <option value="input">入力</option>
              <option value="data">データ内</option>
              <option value="ym">ファイル名から（YYYYMM）※ex) 202401</option>
              <option value="file">ファイル名から（MMYY）※Apple形式</option>
              <option v-if="form.fileType === 'xls' || form.fileType === 'xlsx'" value="sheet">Excelシート</option>
            </select>
          </div>
          <div class="form-label align-bottom">
            データ詳細
          </div>
          <div class="input-group input-group-sm">
            <span class="input-group-text btn-group-sm">項目数</span>
            <input v-model="itemCount" type="number" class="form-control form-control-sm" style="flex: 0 1 65px;">
            <button @click="setItem" class="btn btn-outline-primary btn-sm"><i class="fas fa-check"></i></button>
          </div>
          <div class="form-edit-line">
            <table class="table table-responsive">
              <thead>
                <tr>
                  <th style="width: 40px;">#</th>
                  <th style="width: 70px;">必須</th>
                  <th style="width: 90px;">マッチ</th>
                  <th>種別</th>
                  <th style="width: 150px;">集計</th>
                  <th style="width: 50px;"></th>
                  <th style="width: 20px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in form.itemList" :key="index" draggable="true"
                    @dragstart="dragList($event, index)" @drop="dropList($event, index)" @dragover.prevent
                    @dragenter.prevent :class="{'deleted-row': item.deleted}">
                  <td>{{index + 1}}</td>
                  <td>
                    <select v-model="item.required" class="form-select form-select-sm" style="width: 60px;" :disabled="item.deleted">
                      <option value="false">-</option>
                      <option value="true" v-if="item.itemType !== 'none'">●</option>
                    </select>
                  </td>
                  <td>
                    <select v-model="item.matching" class="form-select form-select-sm" style="width: 80px;" :disabled="item.deleted">
                      <option value="false">-</option>
                      <option value="true" v-if="item.itemType !== 'none' && item.itemType.indexOf('used') === -1 && item.itemType.indexOf('tax') === -1">●</option>
                    </select>
                  </td>
                  <td>
                    <select v-model="item.itemType" class="form-select form-select-sm" :disabled="item.deleted">
                      <option value="none">-</option>
                      <option value="catalog_no">製品番号</option>
                      <option value="jan_code">JANコード（POSコード）</option>
                      <option value="user_album_code">利用者アルバムコード</option>
                      <option value="isrc">ISRC</option>
                      <option value="master_code">原盤管理コード</option>
                      <option value="user_master_code">利用者原盤コード</option>
                      <option value="matching_code">種類が混在したコード</option>
                      <option value="album_title">アルバム名</option>
                      <option value="master_name">原盤名</option>
                      <option value="album_or_master_name">アルバム名／原盤名</option>
                      <option value="artist_name">アーティスト名</option>
                      <option value="used_year">使用年</option>
                      <option value="used_month">使用月</option>
                      <option value="used_year_month_1">使用年月（YYYYMM）</option>
                      <option value="used_year_month_2">使用年月（YYYY/MM）</option>
                      <option value="used_year_month_3">使用年月（YYYY-MM）</option>
                      <option value="used_year_month_4">使用年月（YYYY年MM月）</option>
                      <option value="use_type_name">利用種別</option>
                      <option value="royalty_type_name">印税種別</option>
                      <option value="used_no">使用数</option>
                      <option value="return_no">返品数</option>
                      <option value="price_out_tax">価格（税抜）</option>
                      <option value="price_in_tax">価格（税込）</option>
                      <option value="revenue_out_tax">売上（税抜）</option>
                      <option value="revenue_in_tax">売上（税込）</option>
                      <option value="unit_price">分配単価</option>
                      <option value="div_rate">分配率</option>
                      <option value="income_amount_out_tax">受領額（税抜）</option>
                      <option value="income_amount_in_tax">受領額（税込）</option>
                      <option value="tax_rate">消費税率</option>
                      <option value="memo">メモ</option>
                    </select>
                  </td>
                  <td>
                    <select v-model="item.groupType" class="form-select form-select-sm" :disabled="item.deleted">
                      <option value="none">-</option>
                      <option value="key" v-if="item.itemType !== 'none'">集計キー</option>
                      <option value="sum" v-if="item.itemType !== 'none' && (item.itemType === 'used_no' || item.itemType === 'return_no' || item.itemType.indexOf('tax') !== -1)">合計</option>
                      <option value="max" v-if="item.itemType !== 'none'">最大</option>
                      <option value="min" v-if="item.itemType !== 'none'">最小</option>
                      <option value="avg" v-if="item.itemType !== 'none' && (item.itemType === 'used_no' || item.itemType === 'return_no' || item.itemType.indexOf('tax') !== -1)">平均</option>
                    </select>
                  </td>
                  <td class="text-center" style="vertical-align: middle;">
                    <button @click="item.deleted = !item.deleted" class="btn btn-outline-danger btn-sm"><i class="fas fa-trash-alt"></i></button>
                  </td>
                  <td style="cursor: grab; vertical-align: middle;" class="text-end"><i class="fas fa-grip-vertical"></i></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-label align-bottom">メモ</div>
          <div class="form-edit-line">
            <textarea v-model="form.memo" class="form-control" rows="8"></textarea>
          </div>
        </div>
        <div style="height: 50px;"></div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="deleteModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">定義の削除</h5>
          </div>
          <div v-if="form" class="modal-body text-start">
            <div class="alert alert-danger">
              以下の定義を削除しようとしています。削除すると、定義情報の確認ができなくなり、該当定義が紐づいている楽曲、アルバム、原盤の定義情報がクリアされます。</div>
            <div class="form-label align-bottom">名前</div>
            <div class="form-edit-line">
              {{ form.name }}
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="executeDelete" type="button" class="btn btn-danger" style="margin-right: 5px;"><i
                  class="fas fa-trash-alt" style="margin-right: 5px;"></i>注意事項を理解した上で削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index">{{message}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { Modal, Toast } from 'bootstrap'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { definitionFunction } from './function'

export default defineComponent({
  components: { footerComponent, navComponent },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    const errors = ref([])
    const errorToastDiv = ref(null)
    let errorToast = null
    const messages = ref([])
    const messageToastDiv = ref(null)
    let messageToast = null
    const deleteModalDiv = ref(null)
    let deleteModal = null
    const showDeleteButton = ref(false)
    // Form
    const definitionFunc = definitionFunction()
    const form = ref(null)
    const itemCount = ref(1)
    // メソッド
    const save = async () => {
      errors.value = []
      if (!form.value.name) {
        errors.value.push('名前を入力してください')
      }
      let hasGroupSetting = false
      let hasKey = false
      let hasMatch = false
      let hasNone = false
      let hasIncome = false
      let hasUsedNo = false
      let hasUnitPrice = false
      let hasRevenue = false
      let hasDivRate = false
      for (let i = 0; i < form.value.itemList.length; i++) {
        const item = form.value.itemList[i]
        if (item.groupType !== 'none') {
          hasGroupSetting = true
          if (item.groupType === 'key') {
            hasKey = true
          }
        } else {
          if (item.itemType !== 'none') {
            hasNone = true
          }
        }
        if (item.matching === 'true' || item.matching === true) {
          hasMatch = true
        }
        if (item.itemType === 'income_amount_out_tax' || item.itemType === 'income_amount_in_tax') {
          hasIncome = true
        }
        if (item.itemType === 'used_no') {
          hasUsedNo = true
        }
        if (item.itemType === 'unit_price') {
          hasUnitPrice = true
        }
        if (item.itemType === 'revenue_out_tax') {
          hasRevenue = true
        }
        if (item.itemType === 'div_rate') {
          hasDivRate = true
        }
      }
      if (hasUnitPrice && hasUsedNo && !hasIncome) {
        hasIncome = true
      }
      if (hasRevenue && hasDivRate && !hasIncome) {
        hasIncome = true
      }
      // 集計設定ありの場合のエラーチェック
      if (hasGroupSetting) {
        if (hasNone) {
          errors.value.push('集計機能を使う場合には、種別を選択した全ての項目の集計方法を指定してください')
        }
        if (!hasKey) {
          errors.value.push('集計機能を使う場合には、最低でもキーを一つ指定してください')
        }
      }
      if (!hasMatch) {
        errors.value.push('マッチ項目を最低一つ指定してください')
      }
      if (!hasIncome && !hasUsedNo) {
        errors.value.push('使用数、入金額どちらかは必ず指定してください')
      }
      if (!(form.value.incomeAmountType === 'data' || form.value.incomeAmountType === 'data_percent_deduction')) {
        if (!hasUsedNo) {
          errors.value.push('使用数按分の計算を行うためには、使用数を指定する必要があります')
        }
      }
      if (form.value.incomeAmountType === 'unit_price') {
        if (!hasUsedNo) {
          errors.value.push('使用数 × 単価の計算を行うためには、使用数を指定する必要があります')
        }
      }
      if (form.value.incomeAmountType === 'data' || form.value.incomeAmountType === 'data_percent_deduction' || form.value.incomeAmountType === 'data_all_amount_deduction') {
        if (!hasIncome) {
          errors.value.push('データ内の入金額を取り込むためには、入金額を指定する必要があります')
        }
      }
      // for (let index = 0; index < form.value.itemList.length; index++) {
      //   const item = form.value.itemList[index]
      //   if (form.value.itemList.some((x, i) => x.itemType !== 'none' && x.itemType !== 'memo' && x.itemType === item.itemType && i !== index)) {
      //     errors.value.push('同じ種別が複数設定されています')
      //     break
      //   }
      // }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        if (!form.value.id) {
          form.value.id = await definitionFunc.insert(form.value)
        } else {
          await definitionFunc.update(form.value.id, form.value)
        }
        definitionFunc.indexUpdate(form.value.id)
        await router.push('/red/definition/disp/' + form.value.id)
      }
    }
    const openDeleteModal = () => {
      deleteModal.show()
    }
    const executeDelete = async () => {
      await definitionFunc.remove(form.value.id)
      deleteModal.hide()
      await router.push('/red/definition/list')
    }
    const setItem = () => {
      for (let i = form.value.itemList.length; i < itemCount.value; i++) {
        form.value.itemList.push({
          required: false,
          itemType: 'none',
          groupType: 'none',
          matching: false,
          register_or_through: 'register',
          rot_strings: []
        })
      }
      if (form.value.itemList.length > itemCount.value) {
        form.value.itemList.splice(itemCount.value, (form.value.itemList.length - itemCount.value))
      }
    }
    const dragList = (event, dragIndex) => {
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.setData('drag-index', dragIndex)
    }
    const dropList = (event, dropIndex) => {
      const dragIndex = parseInt(event.dataTransfer.getData('drag-index'))
      const deleteList = form.value.itemList.splice(dragIndex, 1)
      form.value.itemList.splice(dropIndex, 0, deleteList[0])
    }
    const init = async (id) => {
      showDeleteButton.value = false
      if (route.path.includes('/add')) {
        form.value = {
          id: null,
          name: '',
          dataStartRow: 1,
          incomeAmountType: 'data',
          multiMatchingType: 'or',
          usedMonthType: 'input',
          memo: '',
          itemList: [{
            required: false,
            itemType: 'none',
            groupType: 'none',
            matching: false,
            register_or_through: 'register',
            rot_strings: []
          }]
        }
      } else {
        form.value = await definitionFunc.get(id)
        itemCount.value = form.value.itemList.length
        if (itemCount.value === 0) {
          itemCount.value = 1
        }
        if (route.path.includes('/copy')) {
          form.value.id = null
          form.value.name = 'コピー 〜 ' + form.value.name
          messages.value = ['コピーされました。保存ボタンを押すまでは登録されていませんのでご注意ください。']
          messageToast.show()
        }
      }
    }
    const fileTypeChange = () => {
      if ((form.value.fileType === 'tsv' || form.value.fileType === 'csv') && form.value.usedMonthType === 'sheet') {
        form.value.usedMonthType = 'input'
      }
    }
    onMounted(async () => {
      errorToast = new Toast(errorToastDiv.value, {})
      messageToast = new Toast(messageToastDiv.value, {})
      deleteModal = new Modal(deleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await init(route.params.id)
    })
    return {
      router,
      errors,
      errorToastDiv,
      messages,
      messageToastDiv,
      deleteModalDiv,
      showDeleteButton,
      form,
      itemCount,
      save,
      openDeleteModal,
      executeDelete,
      setItem,
      dragList,
      dropList,
      fileTypeChange
    }
  }
})
</script>
