<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#"><img src="../../assets/logo_nav.png" height="24"></a>
      </div>
    </nav>
    <div v-if="!validAccess" class="container-fluid text-start">
      <div class="alert alert-danger text-start">
        <p style="font-size: 120%;">URLが無効です</p>
        <p style="margin-bottom: 0;">※このエラーは既に登録処理が完了しているURLに再度アクセスした場合にも表示されます。</p>
        <p style="margin-bottom: 0;">※QRコードからURLを読み込んだ、もしくは正しいURLを入力し、まだアカウント登録を実施していないにも関わらずこのエラーが表示される場合には、本画面下部に記載の連絡先よりお問い合わせください。</p>
      </div>
      <footer class="alert alert-warning" style="margin-top: 30px;">
        <div class="d-flex justify-content-end" style="margin-bottom: 10px;">
          <img src="../../assets/logo_corp.png" style="max-width: 200px;">
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          東京ライツサービス株式会社
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          〒151-0053 東京都渋谷区代々木1-30-14
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          050-3138-2564
        </div>
        <div class="text-end" style="margin-left: 8px; font-size: 90%;">
          <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
        </div>
        <div class="text-end" style="margin-left: 8px; font-size: 90%;">
          <a href="https://www.rights.tokyo" target="_blank">https://www.rights.tokyo</a>
        </div>
      </footer>
    </div>
    <div v-if="completed" class="container-fluid text-start">
      <div class="alert alert-success text-start">
        <p style="font-size: 120%;">登録が完了しました</p>
        <p style="margin-bottom: 0;">登録にご協力いただきありがとうございます。明細が発行されるとご登録のメールアドレス宛に案内メールが届きます。メールアドレス「green@rights.services」からのメールが迷惑メールにならないように設定をお願いいたします。</p>
        <p style="margin-bottom: 0;">※ログインをお試しになりたい場合は「<a href="https://www.rights.services/login" target="_blank">https://www.rights.services/login</a>」よりお試しください。</p>
        <p style="margin-bottom: 0;">※この画面はこのまま閉じてください。（ブラウザの「戻る」や「更新」を行うとエラーが発生します）</p>
      </div>
      <footer class="alert alert-warning" style="margin-top: 30px;">
        <div class="d-flex justify-content-end" style="margin-bottom: 10px;">
          <img src="../../assets/logo_corp.png" style="max-width: 200px;">
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          東京ライツサービス株式会社
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          〒151-0053 東京都渋谷区代々木1-30-14
        </div>
        <div class="text-end" style="margin-left: 8px; font-size: 90%;">
          <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
        </div>
      </footer>
    </div>
    <div v-if="rejected" class="container-fluid text-start">
      <div class="alert alert-success text-start">
        <p style="font-size: 120%;">収集拒否の処理が完了しました</p>
        <p style="margin-bottom: 0;">※メールアドレスの収集を拒否したことは自動的に依頼元に通知されています。</p>
        <p style="margin-bottom: 0;">※この画面はこのまま閉じてください。（ブラウザの「戻る」や「更新」を行うとエラーが発生します）</p>
      </div>
      <footer class="alert alert-warning" style="margin-top: 30px;">
        <div class="d-flex justify-content-end" style="margin-bottom: 10px;">
          <img src="../../assets/logo_corp.png" style="max-width: 200px;">
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          東京ライツサービス株式会社
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          〒151-0053 東京都渋谷区代々木1-30-14
        </div>
        <div class="text-end" style="margin-left: 8px; font-size: 90%;">
          <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
        </div>
      </footer>
    </div>
    <div v-if="validAccess && !completed && !rejected" class="container-fluid text-start">
      <div class="alert alert-info text-start">
        <p>明細の受領に使用するメールアドレスを入力してください。既に他社からの登録依頼で登録をおこなっている場合でも、お手数ですが再度登録をお願いいたします。なお、メールアドレスが既に登録済みの場合には、自動的にアカウントが統合されます。</p>
        <p>依頼主、権利者名に心当たりがない場合には、お手数をおかけしますが、画面下部にある「心当たりのない依頼のため登録を拒否する」ボタンを押してくださいますようお願い申し上げます。</p>
        <p style="margin-top: 20px; margin-bottom: 0;">この処理により登録された個人情報は、当システムの<a href="https://www.rights.services/help/policy" target="_blank" style="margin-left: 5px; margin-right: 5px;">プライバシーポリシー<i class="fas fa-external-link" style="margin-left: 5px;"></i></a>に則りお取り扱い致します。</p>
      </div>
      <div v-if="companyName !== stCompanyName" class="form-label align-bottom">登録依頼主</div>
      <div v-if="companyName !== stCompanyName" class="form-edit-line">
        {{companyName}}
      </div>
      <div class="form-label align-bottom">明細発行会社</div>
      <div class="form-edit-line">
        {{stCompanyName}}
      </div>
      <div class="form-label align-bottom">権利者名</div>
      <div class="form-edit-line">
        {{name}}<span style="margin-left: 5px; font-size: 85%;" v-if="code">({{code}})</span>
      </div>
      <div v-if="penName" class="form-label align-bottom">筆名</div>
      <div v-if="penName" class="form-edit-line">
        {{penName}}
      </div>
      <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>メールアドレス</div>
      <div class="form-edit-line">
        <input v-if="!isAuthCodeSent" v-model="email" type="text" class="form-control">
        <input v-if="isAuthCodeSent" v-model="email" type="text" class="form-control" readonly>
      </div>
      <div v-if="isAuthCodeSent && !isExistsAccount" class="alert alert-danger">未登録のメールアドレスのため、お名前を入力の上登録をおこなってください。</div>
      <div v-if="isAuthCodeSent && !isExistsAccount" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>名前</div>
      <div v-if="isAuthCodeSent && !isExistsAccount" class="form-edit-line">
        <input v-model="newName" type="text" class="form-control">
      </div>
      <div v-if="isAuthCodeSent" class="alert alert-danger"><b style="font-size: 110%;">まだ登録は完了していません。</b>入力したメールアドレス宛に届いた認証コードを↓の認証コード欄に入力し、「<i class="fas fa-cloud-upload"></i> 登録する」ボタンを押すと登録が完了します。</div>
      <div v-if="isAuthCodeSent" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>認証コード</div>
      <div v-if="isAuthCodeSent" class="form-edit-line">
        <input v-model="authCode" type="text" class="form-control">
      </div>
      <div class="d-flex justify-content-end" style="margin-top: 20px;">
        <button @click="sendAuthCode" v-if="!isAuthCodeSent" type="button" class="btn btn-primary"><i class="fas fa-paper-plane" style="margin-right: 5px;"></i>登録のため認証コードを送信する</button>
        <button @click="save" v-if="isAuthCodeSent" type="button" class="btn btn-primary"><i class="fas fa-cloud-upload" style="margin-right: 5px;"></i>登録する</button>
      </div>
      <div class="d-flex justify-content-end" style="margin-top: 20px;">
        <button @click="openRejectModal" type="button" class="btn btn-danger"><i class="fas fa-ban" style="margin-right: 5px;"></i>心当たりのない依頼のため登録を拒否する</button>
      </div>
      <footer class="alert alert-warning" style="margin-top: 30px;">
        <div class="d-flex justify-content-end" style="margin-bottom: 10px;">
          <img src="../../assets/logo_corp.png" style="max-width: 200px;">
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          東京ライツサービス株式会社
        </div>
        <div class="text-end" style="margin-left: 20px; font-size: 90%;">
          〒151-0053 東京都渋谷区代々木1-30-14
        </div>
        <div class="text-end" style="margin-left: 8px; font-size: 90%;">
          <a href="mailto:support@monstrs.rights.tokyo">support@monstrs.rights.tokyo</a>
        </div>
      </footer>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{processMessage}}
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="rejectModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">確認メッセージ</h5>
          </div>
          <div class="modal-body text-start">
            登録の拒否をしようとしています。本当によろしいですか？
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"><i class="fas fa-times" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button @click="reject" type="button" class="btn btn-danger"><i class="fas fa-ban" style="margin-right: 5px;"></i>拒否する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-success" role="alert" aria-live="assertive" aria-atomic="true" ref="messageToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-info-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">メッセージ</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(message, index) in messages" :key="index">{{message}}</p>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{error}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { Modal, Toast } from 'bootstrap'
import { useRoute } from 'vue-router'
import axios from 'axios'
import exception from '@/functions/exception'

export default defineComponent({
  setup () {
    const validAccess = ref(true)
    const completed = ref(false)
    const rejected = ref(false)
    const route = useRoute()
    const { identifyEx } = exception()
    const system = ref('')
    const token = ref('')
    const rightholderId = ref('')
    const messages = ref([])
    const errors = ref([])
    const messageToastDiv = ref(null)
    const errorToastDiv = ref(null)
    const processMessage = ref('')
    const processModalDiv = ref(null)
    const rejectModalDiv = ref(null)
    let messageToast = null
    let errorToast = null
    let processModal = null
    let rejectModal = null
    const isAuthCodeSent = ref(false)
    // Form
    const companyName = ref('')
    const stCompanyName = ref('')
    const code = ref('')
    const name = ref('')
    const penName = ref('')
    const email = ref('')
    const newName = ref('')
    const authCode = ref('')
    const isExistsAccount = ref(false)
    const sendAuthCode = async () => {
      messages.value = []
      errors.value = []
      if (!email.value) {
        errors.value.push('メールアドレスを入力してください')
      } else {
        const reg = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/
        if (!reg.test(email.value)) {
          errors.value.push('メールアドレスを正しく入力してください')
        }
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = '認証コードの送信中です。しばらくお待ちください。'
        processModal.show()
        await axios.get(process.env.VUE_APP_API_ENDPOINT + '/auth/code/email/' + email.value).catch(error => { identifyEx(error) })
        await axios.put(
          process.env.VUE_APP_GREEN_ENDPOINT + '/destination/collect/' + system.value + '/' + rightholderId.value + '/' + token.value, {
            email: email.value
          }, {}
        ).catch(error => { identifyEx(error) })
        isExistsAccount.value = await existsAccount(email.value)
        isAuthCodeSent.value = true
        processModal.hide()
        messages.value.push('認証コードが送信されました')
        messageToast.show()
      }
    }
    const save = async () => {
      messages.value = []
      errors.value = []
      if (!authCode.value) {
        errors.value.push('認証コードを入力してください')
      }
      if (!isExistsAccount.value && !newName.value) {
        errors.value.push('名前を入力してください')
      }
      if (errors.value.length > 0) {
        errorToast.show()
      } else {
        processMessage.value = 'メールアドレスの登録処理中です。しばらくお待ちください。'
        processModal.show()
        try {
          await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/collect/' + system.value + '/' + rightholderId.value + '/' + token.value, {
            email: email.value,
            authCode: authCode.value,
            name: newName.value
          })
          completed.value = true
        } catch (error) {
          errors.value.push('保存できませんでした。認証コードに誤りがないかご確認ください。')
          errorToast.show()
        }
        processModal.hide()
      }
    }
    const openRejectModal = () => {
      rejectModal.show()
    }
    const reject = async () => {
      rejectModal.hide()
      processMessage.value = 'メールアドレス収集の拒否処理中です。しばらくお待ちください。'
      processModal.show()
      await axios.post(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/collect/' + system.value + '/' + rightholderId.value + '/' + token.value + '/reject', {})
      rejected.value = true
      processModal.hide()
    }
    async function existsAccount (email) {
      const response = await axios.get(process.env.VUE_APP_API_ENDPOINT + '/account/' + email + '/exists', {}).catch(error => { identifyEx(error) })
      return response.data
    }
    onMounted(() => {
      system.value = route.params.system
      rightholderId.value = route.params.id
      token.value = route.params.token
      messageToast = new Toast(messageToastDiv.value, {})
      errorToast = new Toast(errorToastDiv.value, {})
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      rejectModal = new Modal(rejectModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/collect/' + system.value + '/' + rightholderId.value + '/' + token.value, {}).then(response => {
        code.value = response.data.code
        name.value = response.data.name
        penName.value = response.data.penName
        companyName.value = response.data.companyName
        stCompanyName.value = response.data.stCompanyName
      }).catch(() => {
        validAccess.value = false
      })
    })
    return {
      validAccess,
      completed,
      rejected,
      system,
      rightholderId,
      token,
      messages,
      errors,
      processMessage,
      messageToastDiv,
      errorToastDiv,
      processModalDiv,
      rejectModalDiv,
      isAuthCodeSent,
      companyName,
      stCompanyName,
      code,
      name,
      penName,
      email,
      newName,
      authCode,
      isExistsAccount,
      sendAuthCode,
      save,
      reject,
      openRejectModal
    }
  }
})
</script>
