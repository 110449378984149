<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-4 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-file-plus-minus fa-fw" style="margin-right: 5px;"></i> 使用実績ファイル一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="openRegisterModal" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-cloud-upload-alt fa-fw" style="margin-right: 5px;"></i>一括取込</button>
              <button @click="downloadStatusList" class="btn btn-outline-primary"><i class="fas fa-file-download fa-fw" style="margin-right: 5px;"></i>取込状況一覧</button>
              <button v-if="showDeleteButton" @click="openCalculateModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-calculator fa-fw" style="margin-right: 5px;"></i>全て分配計算</button>
              <button v-if="showDeleteButton" @click="openImportModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-file-import fa-fw" style="margin-right: 5px;"></i>全て再取込</button>
              <button v-if="showDeleteButton" @click="openDeleteModal" class="btn btn-danger" style="margin-left: 5px;"><i class="fas fa-trash-alt fa-fw" style="margin-right: 5px;"></i>全削除</button>
              <button @click="showDeleteButton = !showDeleteButton" class="btn" style="padding-right: 0;"><i class="fas fa-caret-left"></i></button>
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input @keydown.enter="store.page = 0; find();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件">
            <select v-model="store.status" @change="store.page = 0; find();" class="form-select" style="flex: 0 1 150px;">
              <option value="all">全て</option>
              <option value="todo">未取込</option>
              <option value="done">取込済</option>
              <option value="none">ファイルなし</option>
            </select>
            <select v-model="store.incomeAmountType" @change="store.page = 0; find();" class="form-select" style="flex: 0 1 150px;">
              <option value="all">全て</option>
              <option value="input">総受領額入力</option>
              <option value="unit">単価入力</option>
            </select>
            <button @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
            <button @click="store.page = 0; find();" class="btn btn-primary"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <table v-if="userSalesResult && userSalesResult.allRecords > 0" class="table table-responsive table-hover" style="font-size: 90%;">
            <thead>
              <tr>
                <th style="width: 80px;"></th>
                <th>利用者</th>
                <th>頒布形態</th>
                <th class="d-none d-lg-table-cell">定義</th>
                <th style="width: 100px;">ステータス</th>
              </tr>
            </thead>
            <tbody v-if="userSalesList">
              <tr v-for="usage in userSalesList" :key="usage.id" class="text-start align-middle" :class="{ 'no-data-row': usage.dataNotExists }">
                <td v-if="!usage.dataNotExists" class="text-center">
                  <button @click="router.push('/red/usage/register/' + usage.id)" class="btn btn-sm btn-outline-primary">
                    <i class="fas fa-cloud-upload fa-fw" style="margin-right: 3px;"></i>
                    <i class="fas fa-magnifying-glass-plus fa-fw"></i>
                  </button>
                </td>
                <td v-else class="text-center">
                  <button @click="router.push('/red/usage/register/' + usage.id)" class="btn btn-sm btn-outline-primary"><i class="fas fa-edit fa-fw"></i></button>
                </td>
                <td>{{ usage.userName }}</td>
                <td>{{ usage.name }}</td>
                <td class="d-none d-lg-table-cell">{{ usage.definitionName }}</td>
                <td class="text-center">
                  <span v-if="usage.status === 'done'" class="badge bg-success">取込済</span>
                  <span v-else-if="usage.status === 'none'" class="badge bg-secondary">ファイルなし</span>
                  <span v-else class="badge bg-primary">未取込</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="userSalesResult && userSalesResult.allRecords > 0">{{ userSalesResult.allRecords }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="userSalesResult && userSalesResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="userSalesResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{ store.page + 1 }}/{{ userSalesResult.allPages }}ページ</span></li>
                <li v-if="store.page !== userSalesResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== userSalesResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="registerModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">一括取込</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom">取込データをまとめた7zファイル</div>
            <div class="form-edit-line">
              <input class="form-control" type="file" id="uploadFile" accept=".7z" ref="uploadFile">
            </div>
            <div class="alert alert-success" style="margin-top: 25px;">
              <div><b>一括取込を行うためには「7z」形式でデータを圧縮する必要があります。下記リンクよりソフトウェアをインストールして作成してください。</b></div>
              <div><b>一括取込を行うためには、先に頒布形態でファイル特定条件を設定する必要があります。</b></div>
              <div style="margin-top: 10px;">※7zとは : <a href="https://ja.wikipedia.org/wiki/7z" target="_blank">https://ja.wikipedia.org/wiki/7z</a></div>
              <div>※7z圧縮を行うためのソフトウェア : <a href="https://www.7-zip.org/" target="_blank">https://www.7-zip.org/</a>（Windows）、<a href="https://www.keka.io/ja/" target="_blank">https://www.keka.io/ja/</a>（Mac）</div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button @click="upload" class="btn btn-primary"><i class="fas fa-cloud-upload-alt" style="margin-right: 5px;"></i>取込</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="deleteModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">全実績データの削除</h5>
          </div>
          <div class="modal-body text-start">
            <div class="alert alert-danger">
              今期取り込んだ全実績データを削除しようとしています。
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="executeDelete" type="button" class="btn btn-danger" style="margin-right: 5px;"><i class="fas fa-trash-alt" style="margin-right: 5px;"></i>削除を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="calculateModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">今期取り込んだ全データの分配計算実行</h5>
          </div>
          <div class="modal-body text-start">
            <div class="alert alert-danger">
              今期取り込んだ全実績データの分配計算を実行しようとしています。通常、分配計算はリアルタイムに処理されるため、この処理を実行する必要はありません。計算されていないものがありそうな場合のみ実行するようにしてください。
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="executeCalculate" type="button" class="btn btn-danger" style="margin-right: 5px;"><i class="fas fa-trash-alt" style="margin-right: 5px;"></i>分配計算を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processCalcModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">分配計算実行中</h5>
          </div>
          <div class="modal-body text-start">
            <div class="alert alert-danger fs-5">
              分配計算実行中です。完了するまで画面を閉じたりページを移動したりしないでください。
            </div>
            <div class="fs-5 text-center" style="margin-bottom: 10px;">
              進行状況 {{calcProcessCount}} / {{calcProcessCountAll}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="importModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">今期取り込んだ全データの再取込</h5>
          </div>
          <div class="modal-body text-start">
            <div class="alert alert-danger">
              今期取り込んだ全実績データの再取込を実施しようとしています。
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="executeImport" type="button" class="btn btn-danger" style="margin-right: 5px;"><i class="fas fa-trash-alt" style="margin-right: 5px;"></i>再取込を実行する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processImportModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">分配計算実行中</h5>
          </div>
          <div class="modal-body text-start">
            <div class="alert alert-danger fs-5">
              分配計算実行中です。完了するまで画面を閉じたりページを移動したりしないでください。
            </div>
            <div class="fs-5 text-center" style="margin-bottom: 10px;">
              進行状況 {{importProcessCount}} / {{importProcessCountAll}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { usageStore } from './store'
import { usageFunction } from './function'
import { redCompanyFunction, attachmentFunction } from '@/functions/common'
import { Modal } from 'bootstrap'
import axios from 'axios'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const auth = authFunction()
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    const session = sessionStore()
    // 検索
    const usageFunc = usageFunction()
    const companyFunc = redCompanyFunction()
    const store = usageStore()
    const userSalesResult = ref(null)
    const userSalesList = ref([])
    // 一括取込
    const registerModalDiv = ref(null)
    let registerModal = null
    const uploadFile = ref(null)
    const attachmentFunc = attachmentFunction()
    // 一括削除
    const deleteModalDiv = ref(null)
    let deleteModal = null
    const showDeleteButton = ref(false)
    // 一括計算
    const calculateModalDiv = ref(null)
    let calculateModal = null
    const processCalcModalDiv = ref(null)
    let processCalcModal = null
    const calcProcessCount = ref(0)
    const calcProcessCountAll = ref(0)
    // 再取込
    const importModalDiv = ref(null)
    let importModal = null
    const processImportModalDiv = ref(null)
    let processImportModal = null
    const importProcessCount = ref(0)
    const importProcessCountAll = ref(0)
    // メソッド
    const find = async () => {
      const divTerm = await companyFunc.getCurrentDivTerm(session.session.company.id)
      userSalesResult.value = await usageFunc.find(session.session.company.id, divTerm.id)
      userSalesList.value = userSalesResult.value.list
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    const clearSearch = () => {
      store.searchString = ''
      store.page = 0
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = userSalesResult.value.allPages - 1
      find()
    }
    const downloadStatusList = async () => {
      processMessage.value = '状況一覧の作成中です。しばらくお待ちください。'
      processModal.show()
      const divTerm = await companyFunc.getCurrentDivTerm(session.session.company.id)
      const url = await usageFunc.getStatusListUrl(divTerm.id)
      const a = document.createElement('a')
      a.href = url
      a.click()
      processModal.hide()
    }
    const openRegisterModal = () => {
      registerModal.show()
    }
    const openDeleteModal = () => {
      deleteModal.show()
    }
    const upload = async () => {
      if (uploadFile.value.files.length === 0) return
      const file = uploadFile.value.files[0]
      processMessage.value = 'ファイルアップロード中です。しばらくお待ちください。'
      processModal.show()
      registerModal.hide()
      const blobName = 'red/batch/upload//' + uuidV4() + '/' + file.name
      const url = await attachmentFunc.getUrlForUpload(blobName)
      await axios.put(url, file, {
        headers: { 'Content-Type': 'application/octet-stream' }
      })
      const result = await usageFunc.batchRegister(blobName, session.session.company.id)
      uploadFile.value.value = ''
      console.log(result)
      processMessage.value = '実績データ取込中です。しばらくお待ちください。'
      for (const uploadedFileId of result) {
        await usageFunc.register(uploadedFileId, true, 0, 0, 0, 0)
      }
      processModal.hide()
    }
    const uuidV4 = () => {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0; const v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    }
    const executeDelete = async () => {
      processMessage.value = '削除処理中です。しばらくお待ちください。'
      processModal.show()
      deleteModal.hide()
      await usageFunc.batchDelete(session.session.company.id)
      processModal.hide()
    }
    const openCalculateModal = () => {
      calculateModal.show()
    }
    const openImportModal = () => {
      importModal.show()
    }
    const executeCalculate = async () => {
      processCalcModal.show()
      calculateModal.hide()
      const fileList = await usageFunc.findUploadedFileAll(session.session.company.id)
      calcProcessCountAll.value = fileList.length
      calcProcessCount.value = 0
      console.log(fileList)
      const promises = []
      for (const uploadedFile of fileList) {
        promises.push(calculateMain(uploadedFile.id))
      }
      await Promise.all(promises)
      processCalcModal.hide()
    }
    const executeImport = async () => {
      processImportModal.show()
      importModal.hide()
      const fileList = await usageFunc.findUploadedFileAll(session.session.company.id)
      importProcessCountAll.value = fileList.length
      importProcessCount.value = 0
      console.log(fileList.length)
      const promises = []
      for (const uploadedFile of fileList) {
        promises.push(importMain(uploadedFile.id, false, uploadedFile.incomeAmount, uploadedFile.deductionRate, uploadedFile.deductionAmount, uploadedFile.unitPrice))
      }
      console.log('import start')
      await Promise.all(promises)
      console.log('import end')
      processImportModal.hide()
      console.log('calculate start')
      await executeCalculate()
    }
    const calculateMain = async (uploadedFileId) => {
      await usageFunc.calculateSync(uploadedFileId)
      calcProcessCount.value++
    }
    const importMain = async (uploadedFileId, continueOnError, incomeAmount, deductionRate, deductionAmount, unitPrice) => {
      await usageFunc.register(uploadedFileId, continueOnError, incomeAmount, deductionRate, deductionAmount, unitPrice)
      importProcessCount.value++
    }
    onMounted(async () => {
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      registerModal = new Modal(registerModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      deleteModal = new Modal(deleteModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      calculateModal = new Modal(calculateModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      importModal = new Modal(importModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      processCalcModal = new Modal(processCalcModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      processImportModal = new Modal(processImportModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await find()
    })
    return {
      processMessage,
      processModalDiv,
      router,
      store,
      userSalesResult,
      userSalesList,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
      downloadStatusList,
      registerModalDiv,
      openRegisterModal,
      uploadFile,
      upload,
      showDeleteButton,
      deleteModalDiv,
      executeDelete,
      openDeleteModal,
      calculateModalDiv,
      openCalculateModal,
      processCalcModalDiv,
      executeCalculate,
      calcProcessCount,
      calcProcessCountAll,
      importModalDiv,
      openImportModal,
      processImportModalDiv,
      executeImport,
      importProcessCount,
      importProcessCountAll
    }
  }
})
</script>
<style scoped>
.no-data-row {
  background-color: #dddddd;
}
</style>
