<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/result/list"><i class="fas fa-binary-circle-check fa-fw" style="margin-right: 5px;"></i>
                      リアルタイム分配結果一覧
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="form-label align-bottom">権利者名</div>
          <div class="form-edit-line">
            <button @click="openRightsHolderRefModal(form.rightsHolderId)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
            {{ form.rightsHolderName }}
          </div>
          <div class="form-label align-bottom">計算単位</div>
          <div class="form-edit-line">
            <span v-if="form.dataUnit === 'album'">アルバム</span>
            <span v-if="form.dataUnit === 'master'">原盤</span>
          </div>
          <div v-if="form.albumId" class="form-label align-bottom">アルバム名</div>
          <div v-if="form.albumId" class="form-edit-line">
            <button @click="openAlbumRefModal(form.albumId)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
            {{form.albumName}}
          </div>
          <div v-if="form.dataUnit === 'album' && form.albumCode" class="form-label align-bottom">アルバムコード</div>
          <div v-if="form.dataUnit === 'album' && form.albumCode" class="form-edit-line">
            {{form.albumCode}}
          </div>
          <div v-if="form.dataUnit === 'album' && form.catalogNo" class="form-label align-bottom">品番</div>
          <div v-if="form.dataUnit === 'album' && form.catalogNo" class="form-edit-line">
            {{form.catalogNo}}
          </div>
          <div v-if="form.dataUnit === 'album' && form.janCode" class="form-label align-bottom">JANコード</div>
          <div v-if="form.dataUnit === 'album' && form.janCode" class="form-edit-line">
            {{form.janCode}}
          </div>
          <div v-if="form.dataUnit === 'master'" class="form-label align-bottom">原盤名</div>
          <div v-if="form.dataUnit === 'master'" class="form-edit-line">
            <button @click="openMasterRefModal(form.masterId)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
            {{form.masterName}}
          </div>
          <div v-if="form.dataUnit === 'master' && form.masterCode" class="form-label align-bottom">原盤コード</div>
          <div v-if="form.dataUnit === 'master' && form.masterCode" class="form-edit-line">
            {{form.masterCode}}
          </div>
          <div v-if="form.dataUnit === 'master' && form.isrc" class="form-label align-bottom">ISRC</div>
          <div v-if="form.dataUnit === 'master' && form.isrc" class="form-edit-line">
            {{form.isrc}}
          </div>
          <div v-if="form.artistId" class="form-label align-bottom">アーティスト</div>
          <div v-if="form.artistId" class="form-edit-line">
            <button @click="openArtistRefModal(form.artistId)" class="btn btn-outline-primary btn-sm" style="margin-right: 5px;"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
            {{form.artistName}}
          </div>
          <div class="form-label align-bottom">利用者名</div>
          <div class="form-edit-line">
            <button @click="openUserRefModal(form.userId)" class="btn btn-outline-primary btn-sm" style="margin-right: 5px;"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
            {{ form.userName }}
          </div>
          <div class="form-label align-bottom">利用種別</div>
          <div class="form-edit-line">
            {{ form.useTypeName }}
          </div>
          <div class="form-label align-bottom">利用形態</div>
          <div class="form-edit-line">
            {{ form.userSalesName }}
          </div>
          <div class="form-label align-bottom">利用年月</div>
          <div class="form-edit-line">
            {{ form.usedYear }}年{{ form.usedMonth }}月
          </div>
          <hr>
          <div class="form-label align-bottom">元データファイル名</div>
          <div class="form-edit-line text-break">
            {{form.fileName}}
          </div>
          <div class="form-label align-bottom">元データ取込日時</div>
          <div class="form-edit-line">
            {{form.importDate}}
          </div>
          <div class="form-label align-bottom">分配結果算出日時</div>
          <div class="form-edit-line">
            {{form.createdAt}}
          </div>
          <hr>
          <div class="form-label align-bottom">適用契約書</div>
          <div class="form-edit-line">
            <button @click="openContractRefModal(form.contractId)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
            {{ form.contractName }}
          </div>
          <div class="form-label align-bottom">印税種別</div>
          <div class="form-edit-line">
            {{ form.royaltyTypeName }}
          </div>
          <div class="form-label align-bottom">計算方法</div>
          <div class="form-edit-line">
            <span v-if="form.calcType === 'unit_rate'">単価計算</span>
            <span v-if="form.calcType === 'unit_price'">固定単価</span>
            <span v-if="form.calcType === 'div_rate'">分配率（受領額）</span>
            <span v-if="form.calcType === 'div_rate_revenue'">分配率（売上）</span>
            <span v-if="form.calcType === 'after_rate'">手残り計算</span>
            <span v-if="form.calcType === 'fix_price'">固定金額</span>
            <br>
            <span style="font-size: 80%;">
              <span v-if="form.calcType === 'unit_rate'">
                算出単価（
                (<span v-if="form.unitPriceBasePrice === 'sales_price_out_tax'">税抜販売価格</span>
                <span v-if="form.unitPriceBasePrice === 'sales_price_in_tax'">税込販売価格</span>
                <span v-if="form.unitPriceBasePrice === 'whole_price_out_tax'">税抜卸価格</span>
                <span v-if="form.unitPriceBasePrice === 'whole_price_in_tax'">税込卸価格</span>
                <span v-if="form.unitPriceBasePrice === 'income_out_tax'">税抜受領額</span>
                <span v-if="form.unitPriceBasePrice === 'income_in_tax'">税込受領額</span>
                -
                (<span v-if="form.jacketDeductionBasePrice === 'sales_price_out_tax'">税抜販売価格</span>
                <span v-if="form.jacketDeductionBasePrice === 'sales_price_in_tax'">税込販売価格</span>
                <span v-if="form.jacketDeductionBasePrice === 'whole_price_out_tax'">税抜卸価格</span>
                <span v-if="form.jacketDeductionBasePrice === 'whole_price_in_tax'">税込卸価格</span>
                <span v-if="form.jacketDeductionBasePrice === 'income_out_tax'">税抜受領額</span>
                <span v-if="form.jacketDeductionBasePrice === 'income_in_tax'">税込受領額</span>
                x
                ジャケット代控除率)) x 印税率）x 計算対象数（使用数 x 出庫率）
              </span>
              <span v-if="form.calcType === 'unit_price'">固定単価 x 計算対象数（使用数 x 出庫率）</span>
              <span v-if="form.calcType === 'div_rate'">(受領額 - トップオフ) x 分配率</span>
              <span v-if="form.calcType === 'div_rate_revenue'">(売上 - トップオフ) x 分配率</span>
              <span v-if="form.calcType === 'fix_price'">指定した金額</span>
              <span v-if="form.calcType === 'after_rate'">手残り額（受領額 - トップオフ - その他の支払）x 分配率</span>
              <span v-if="form.calcType === 'after_rate_revenue'">手残り額（売上 - トップオフ - その他の支払）x 分配率</span>
            </span>
          </div>
          <hr v-if="form.calcType !== 'fix_price'">
          <div class="row">
            <div class="col-lg-6">
              <template v-if="form.unitPriceBasePrice && (form.unitPriceBasePrice.includes('whole_price') || form.jacketDeductionBasePrice.includes('whole_price'))">
                <div class="form-label align-bottom">
                  卸価格算出方法
                </div>
                <div class="form-edit-line">
                  <span v-if="form.wholePriceCalcType === 'direct'">アルバム情報または実績データ内の卸価格を使用</span>
                  <span v-if="form.wholePriceCalcType === 'calculate'">税抜販売価格から卸価格を算出</span>
                </div>
                <div v-show="form.wholePriceCalcType === 'calculate'" class="form-label align-bottom">卸価格率</div>
                <div v-show="form.wholePriceCalcType === 'calculate'" class="form-edit-line">
                <span v-if="form.wholePriceRateType === 'decimal'">
                  {{form.wholePriceRate.toLocaleString(undefined, { maximumFractionDigits: 20 })}}%
                </span>
                  <span v-if="form.wholePriceRateType === 'fraction'">
                  {{form.wholePriceRateC.toLocaleString(undefined, { maximumFractionDigits: 20 })}} / {{form.wholePriceRateM.toLocaleString(undefined, { maximumFractionDigits: 20 })}}
                </span>
                </div>
              </template>
              <div v-if="form.calcType === 'unit_rate' && form.basePrice" class="form-label align-bottom">
                <span v-if="form.unitPriceBasePrice === 'sales_price_out_tax'">税抜販売価格</span>
                <span v-if="form.unitPriceBasePrice === 'sales_price_in_tax'">税込販売価格</span>
                <span v-if="form.unitPriceBasePrice === 'whole_price_out_tax'">税抜卸価格</span>
                <span v-if="form.unitPriceBasePrice === 'whole_price_in_tax'">税込卸価格</span>
                <span v-if="form.unitPriceBasePrice === 'deemed_price_out_tax'">税抜みなし価格</span>
                <span v-if="form.unitPriceBasePrice === 'deemed_price_in_tax'">税込みなし価格</span>
                <span v-if="form.unitPriceBasePrice === 'deemed_whole_price_out_tax'">税抜みなし卸価格</span>
                <span v-if="form.unitPriceBasePrice === 'deemed_whole_price_in_tax'">税込みなし卸価格</span>
                <span v-if="form.unitPriceBasePrice === 'income_out_tax'">税抜受領額</span>
                <span v-if="form.unitPriceBasePrice === 'income_in_tax'">税込受領額</span>
              </div>
              <div v-if="form.calcType === 'unit_rate'" class="form-edit-line">
                {{form.basePrice.toLocaleString(undefined, { maximumFractionDigits: 20 })}}円
              </div>
              <div v-if="form.calcType === 'unit_rate' && form.recordCount > 1" class="form-label align-bottom">案分数</div>
              <div v-if="form.calcType === 'unit_rate' && form.recordCount > 1" class="form-edit-line">
                {{form.recordCount}}
              </div>
              <div v-if="form.portionC !== form.portionM" class="form-label align-bottom">管理比率</div>
              <div v-if="form.portionC !== form.portionM" class="form-edit-line">
                {{form.portionC}} / {{form.portionM}}
              </div>
              <div v-if="form.calcType === 'unit_rate'" class="form-label align-bottom">ジャケット代控除率</div>
              <div v-if="form.calcType === 'unit_rate'" class="form-edit-line">
                <span v-if="form.jacketDeductionRateType === 'decimal'">{{form.jacketDeductionRate.toLocaleString(undefined, { maximumFractionDigits: 20 })}}%</span>
                <span v-else>
                  {{form.jacketDeductionRateC.toLocaleString(undefined, { maximumFractionDigits: 20 })}} / {{form.jacketDeductionRateM.toLocaleString(undefined, { maximumFractionDigits: 20 })}}
                </span>
              </div>
              <div v-if="form.calcType === 'unit_rate'" class="form-label align-bottom">ジャケット代控除額</div>
              <div v-if="form.calcType === 'unit_rate'" class="form-edit-line">
                {{form.jacketDeductionAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}}円<br>
                <span style="font-size: 80%;">
                  {{form.baseJacketPrice.toLocaleString(undefined, { maximumFractionDigits: 20 })}}
                  <span v-if="form.recordCount !== 1">
                  ÷
                  {{form.recordCount}}
                  </span>
                  <span v-if="form.portionC !== form.portionM">
                  x
                  {{form.portionC}}
                  ÷
                  {{form.portionM}}
                  </span>
                  x
                  <span v-if="form.jacketDeductionRateType === 'decimal'">({{form.jacketDeductionRate.toLocaleString(undefined, { maximumFractionDigits: 20 })}} ÷ 100)</span>
                  <span v-else>
                    ({{form.jacketDeductionRateC.toLocaleString(undefined, { maximumFractionDigits: 20 })}} ÷ {{form.jacketDeductionRateM.toLocaleString(undefined, { maximumFractionDigits: 20 })}})
                  </span>
                </span>
              </div>
              <div v-if="form.calcType === 'unit_rate'" class="form-label align-bottom">印税率</div>
              <div v-if="form.calcType === 'unit_rate'" class="form-edit-line">
                <span v-if="form.royaltyRateType === 'decimal'">{{form.royaltyRate.toLocaleString(undefined, { maximumFractionDigits: 20 })}}%</span>
                <span v-else>
                  {{form.royaltyRateC.toLocaleString(undefined, { maximumFractionDigits: 20 })}} / {{form.royaltyRateM.toLocaleString(undefined, { maximumFractionDigits: 20 })}}
                </span>
              </div>
              <div v-if="form.calcType === 'unit_rate' || form.calcType === 'unit_price'" class="form-label align-bottom">印税単価</div>
              <div v-if="form.calcType === 'unit_rate' || form.calcType === 'unit_price'" class="form-edit-line">
                {{form.unitPrice.toLocaleString(undefined, { maximumFractionDigits: 20 })}}円
                <br v-if="form.calcType === 'unit_rate'">
                <span v-if="form.calcType === 'unit_rate'" style="font-size: 80%;">
                  ({{form.basePrice.toLocaleString(undefined, { maximumFractionDigits: 20 })}} <span v-if="form.recordCount > 1">÷ {{form.recordCount}}</span><span v-if="form.portionC !== form.portionM">x {{form.portionC}} ÷ {{form.portionM}}</span> - {{form.jacketDeductionAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}})
                  x
                  <span v-if="form.royaltyRateType === 'decimal'">({{form.royaltyRate}} ÷ 100)</span>
                  <span v-else>
                    ({{form.royaltyRateC.toLocaleString(undefined, { maximumFractionDigits: 20 })}} ÷ {{form.royaltyRateM.toLocaleString(undefined, { maximumFractionDigits: 20 })}})
                  </span>
                </span>
              </div>
            </div>
            <div class="col-lg-6">
              <div v-if="form.calcType.indexOf('unit') !== -1 && form.usedNo" class="form-label align-bottom">使用数</div>
              <div v-if="form.calcType.indexOf('unit') !== -1 && form.usedNo" class="form-edit-line">
                {{form.usedNo.toLocaleString(undefined, { maximumFractionDigits: 20 })}}
              </div>
              <div v-if="form.calcType.indexOf('unit') !== -1 && form.usedNo" class="form-label align-bottom">出庫率</div>
              <div v-if="form.calcType.indexOf('unit') !== -1 && form.usedNo" class="form-edit-line">
                <span v-if="form.shipRateType === 'decimal'">{{form.shipRate.toLocaleString(undefined, { maximumFractionDigits: 20 })}}%</span>
                <span v-else>
                  {{form.shipRateC.toLocaleString(undefined, { maximumFractionDigits: 20 })}} / {{form.shipRateM.toLocaleString(undefined, { maximumFractionDigits: 20 })}}
                </span>
              </div>
              <div v-if="form.calcType.indexOf('unit') !== -1 && form.calcTargetNo" class="form-label align-bottom">計算対象数</div>
              <div v-if="form.calcType.indexOf('unit') !== -1 && form.calcTargetNo" class="form-edit-line">
                {{form.calcTargetNo.toLocaleString(undefined, { maximumFractionDigits: 20 })}}
                <br>
                <span style="font-size: 80%;">
                  {{form.usedNo.toLocaleString(undefined, { maximumFractionDigits: 20 })}}
                  x
                  <span v-if="form.shipRateType === 'decimal'">({{form.shipRate.toLocaleString(undefined, { maximumFractionDigits: 20 })}} ÷ 100)</span>
                  <span v-else>
                    ({{form.shipRateC.toLocaleString(undefined, { maximumFractionDigits: 20 })}} ÷ {{form.shipRateM.toLocaleString(undefined, { maximumFractionDigits: 20 })}})
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div v-if="(form.calcType === 'div_rate' || form.calcType === 'after_rate') && form.incomeAmount" class="form-label align-bottom">受領額（税抜）※外貨の場合、円貨換算後</div>
          <div v-if="(form.calcType === 'div_rate' || form.calcType === 'after_rate') && form.incomeAmount" class="form-edit-line">
            {{form.incomeAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}}円
          </div>
          <div v-if="(form.calcType === 'after_rate_revenue' || form.calcType === 'div_rate_revenue') && form.revenue" class="form-label align-bottom">売上（税抜）※外貨の場合、円貨換算後</div>
          <div v-if="(form.calcType === 'after_rate_revenue' || form.calcType === 'div_rate_revenue') && form.revenue" class="form-edit-line">
            {{form.revenue.toLocaleString(undefined, { maximumFractionDigits: 20 })}}円
          </div>
          <div v-if="form.calcType === 'div_rate' || form.calcType === 'div_rate_revenue' || form.calcType === 'after_rate_revenue' || form.calcType === 'after_rate'" class="form-label align-bottom">トップオフ</div>
          <template v-if="form.calcType === 'div_rate' || form.calcType === 'div_rate_revenue' || form.calcType === 'after_rate_revenue' || form.calcType === 'after_rate'">
            <div class="form-edit-line">
              <p v-for="topOff in form.topOffList" :key="topOff.id" style="margin: 0;">
                <template v-if="topOff.topOffType === 'rate'">
                  <span v-if="topOff.topOffRateType === 'decimal'">{{topOff.topOffRate.toLocaleString(undefined, { maximumFractionDigits: 20 })}}%</span>
                  <span v-else>
                    {{topOff.topOffRateC.toLocaleString(undefined, { maximumFractionDigits: 20 })}} / {{topOff.topOffRateM.toLocaleString(undefined, { maximumFractionDigits: 20 })}}
                  </span>
                  <span v-if="topOff.title">（{{topOff.title}}）</span>
                </template>
                <template v-else>
                  <span>￥{{topOff.topOffAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}}</span>
                  <span v-if="topOff.title">（{{topOff.title}}）</span>
                </template>
              </p>
            </div>
          </template>
          <div v-if="(form.calcType === 'div_rate' || form.calcType === 'div_rate_revenue' || form.calcType === 'after_rate_revenue' || form.calcType === 'after_rate') && form.topOffAmount" class="form-label align-bottom">トップオフ額</div>
          <div v-if="(form.calcType === 'div_rate' || form.calcType === 'div_rate_revenue' || form.calcType === 'after_rate_revenue' || form.calcType === 'after_rate') && form.topOffAmount" class="form-edit-line">
            {{form.topOffAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}}円
            <br>
            <div style="font-size: 80%;">
              <p v-for="topOff in form.topOffList" :key="topOff.id" style="margin: 0;">
                <template v-if="topOff.topOffType === 'rate'">
                  {{form.incomeAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}}
                  x
                  <span v-if="topOff.topOffRateType === 'decimal'">{{topOff.topOffRate.toLocaleString(undefined, { maximumFractionDigits: 20 })}}%</span>
                  <span v-else>
                    {{topOff.topOffRateC.toLocaleString(undefined, { maximumFractionDigits: 20 })}} / {{topOff.topOffRateM.toLocaleString(undefined, { maximumFractionDigits: 20 })}}
                  </span>
                  <span v-if="topOff.title">（{{topOff.title}}）</span>
                </template>
                <template v-else>
                  <span>￥{{topOff.topOffAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}}</span>
                  <span v-if="topOff.title">（{{topOff.title}}）</span>
                </template>
              </p>
            </div>
          </div>
          <div v-if="form.calcType === 'after_rate' || form.calcType === 'after_rate_revenue'" class="form-label align-bottom">先計算支払額</div>
          <div v-if="form.calcType === 'after_rate' || form.calcType === 'after_rate_revenue'" class="form-edit-line">
            {{form.beforeAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}}円
          </div>
          <div v-if="form.calcType === 'after_rate' || form.calcType === 'after_rate_revenue'" class="form-label align-bottom">計算対象額</div>
          <div v-if="form.calcType === 'after_rate' || form.calcType === 'after_rate_revenue'" class="form-edit-line">
            {{form.calcTargetAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}}円<br>
            <span v-if="form.calcType === 'after_rate'" style="font-size: 80%;">{{form.incomeAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}} - {{form.topOffAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}} - {{form.beforeAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}}</span>
            <span v-if="form.calcType === 'after_rate_revenue'" style="font-size: 80%;">{{form.revenue.toLocaleString(undefined, { maximumFractionDigits: 20 })}} - {{form.topOffAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}} - {{form.beforeAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}}</span>
          </div>
          <div v-if="form.calcType === 'div_rate' || form.calcType === 'div_rate_revenue' || form.calcType === 'after_rate' || form.calcType === 'after_rate_revenue'" class="form-label align-bottom">分配率</div>
          <div v-if="form.calcType === 'div_rate' || form.calcType === 'div_rate_revenue' || form.calcType === 'after_rate' || form.calcType === 'after_rate_revenue'" class="form-edit-line">
            <span v-if="form.royaltyRateType === 'decimal'">{{form.royaltyRate.toLocaleString(undefined, { maximumFractionDigits: 20 })}}%</span>
            <span v-else>
              {{form.royaltyRateC.toLocaleString(undefined, { maximumFractionDigits: 20 })}} / {{form.royaltyRateM.toLocaleString(undefined, { maximumFractionDigits: 20 })}}
            </span>
          </div>
          <hr>
          <div v-if="form.groupRightsHolder" class="form-label align-bottom">グループ按分対象額</div>
          <div v-if="form.groupRightsHolder" class="form-edit-line">
            {{form.basePaymentAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}}円
            <br>
            <span v-if="form.calcType === 'div_rate' || form.calcType === 'div_rate_revenue' || form.calcType === 'after_rate' || form.calcType === 'after_rate_revenue'" style="font-size: 80%; font-weight: normal;">
                <span v-if="form.calcType === 'div_rate'">{{form.incomeAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}}</span>
                <span v-if="form.calcType === 'div_rate_revenue'">{{form.revenue.toLocaleString(undefined, { maximumFractionDigits: 20 })}}</span>
                <span v-if="form.calcType === 'after_rate' || form.calcType === 'after_rate_revenue'">{{form.calcTargetAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}}</span>
                x
                <span v-if="form.royaltyRateType === 'decimal'">({{form.royaltyRate.toLocaleString(undefined, { maximumFractionDigits: 20 })}} ÷ 100)</span>
                <span v-else>
                  x {{form.royaltyRateC.toLocaleString(undefined, { maximumFractionDigits: 20 })}} ÷ {{form.royaltyRateM.toLocaleString(undefined, { maximumFractionDigits: 20 })}}
                </span>
            </span>
          </div>
          <div v-if="form.groupRightsHolder" class="form-label align-bottom">グループ按分比率</div>
          <div v-if="form.groupRightsHolder" class="form-edit-line">
            {{form.memberShareC}} / {{form.memberShareM}}
          </div>
          <div class="form-label align-bottom">支払額</div>
          <div class="form-edit-line fs-5" style="font-weight: bold;">
            {{form.paymentAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}}円
            <br>
            <span v-if="form.calcType.indexOf('unit') !== -1" style="font-size: 80%; font-weight: normal;">
              {{form.unitPrice.toLocaleString(undefined, { maximumFractionDigits: 20 })}} x {{form.calcTargetNo.toLocaleString(undefined, { maximumFractionDigits: 20 })}}
            </span>
            <span v-if="form.calcType === 'div_rate' || form.calcType === 'div_rate_revenue' || form.calcType === 'after_rate' || form.calcType === 'after_rate_revenue'" style="font-size: 80%; font-weight: normal;">
                <span v-if=" !form.groupRightsHolder && form.calcType === 'div_rate'">{{form.incomeAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}}</span>
                <span v-if="!form.groupRightsHolder && form.calcType === 'div_rate_revenue'">{{form.revenue.toLocaleString(undefined, { maximumFractionDigits: 20 })}}</span>
                <span v-if="!form.groupRightsHolder && form.calcType === 'after_rate' || form.calcType === 'after_rate_revenue'">{{form.calcTargetAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}}</span>
                <span v-if="!form.groupRightsHolder"> x </span>
                <span v-if="!form.groupRightsHolder && form.royaltyRateType === 'decimal'">({{form.royaltyRate.toLocaleString(undefined, { maximumFractionDigits: 20 })}} ÷ 100)</span>
                <span v-if="!form.groupRightsHolder && form.royaltyRateType !== 'decimal'">
                  x {{form.royaltyRateC.toLocaleString(undefined, { maximumFractionDigits: 20 })}} ÷ {{form.royaltyRateM.toLocaleString(undefined, { maximumFractionDigits: 20 })}}
                </span>
                <span v-if="form.groupRightsHolder">
                  {{form.basePaymentAmount.toLocaleString(undefined, { maximumFractionDigits: 20 })}} x {{form.memberShareC}} ÷ {{form.memberShareM}}
                </span>
            </span>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="artistRefModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div v-if="refArtistId" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">アーティスト詳細</h5>
          </div>
          <div class="modal-body text-start">
            <artist-modal :artist-id="refArtistId"></artist-modal>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeArtistRefModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoArtist(refArtistId)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>アーティスト画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="masterRefModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div v-if="refMasterId" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">原盤詳細</h5>
          </div>
          <div class="modal-body text-start">
            <master-modal :master-id="refMasterId"></master-modal>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeMasterRefModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoMaster(refMasterId)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>原盤画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="albumRefModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div v-if="refAlbumId" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">アルバム詳細</h5>
          </div>
          <div class="modal-body text-start">
            <album-modal :album-id="refAlbumId"></album-modal>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeAlbumRefModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoAlbum(refAlbumId)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>アルバム画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="contractRefModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div v-if="refContractId" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">契約書詳細</h5>
          </div>
          <div class="modal-body text-start">
            <contract-modal :contract-id="refContractId"></contract-modal>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeContractRefModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoContract(refContractId)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>契約書画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="rightsHolderRefModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div v-if="refRightsHolderId" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">権利者詳細</h5>
          </div>
          <div class="modal-body text-start">
            <rights-holder-modal :rightsHolder-id="refRightsHolderId"></rights-holder-modal>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeRightsHolderRefModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoRightsHolder(refRightsHolderId)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>権利者画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="userRefModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div v-if="refUserId" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">利用者詳細</h5>
          </div>
          <div class="modal-body text-start">
            <user-modal :user-id="refUserId"></user-modal>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeUserRefModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoUser(refUserId)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>利用者画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import footerComponent from '@/components/Footer.vue'
import { resultFunction } from './function'
import { Modal } from 'bootstrap'
import masterModal from '@/components/modal/MasterModal'
import albumModal from '@/components/modal/AlbumModal'
import artistModal from '@/components/modal/ArtistModal'
import contractModal from '@/components/modal/ContractModal'
import rightsHolderModal from '@/components/modal/RightsHolderModal'
import userModal from '@/components/modal/UserModal'

export default defineComponent({
  components: { contractModal, artistModal, albumModal, masterModal, footerComponent, navComponent, rightsHolderModal, userModal },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    // Form
    const resultFunc = resultFunction()
    const form = ref(null)
    const rightAreaMode = ref('SALES')
    // 原盤詳細
    const masterRefModalDiv = ref({})
    let masterRefModal = null
    const refMasterId = ref('')
    // アルバム詳細
    const albumRefModalDiv = ref(null)
    let albumRefModal = null
    const refAlbumId = ref('')
    // アーティスト詳細
    const artistRefModalDiv = ref({})
    let artistRefModal = null
    const refArtistId = ref('')
    // 契約書詳細
    const contractRefModalDiv = ref({})
    let contractRefModal = null
    const refContractId = ref('')
    // 権利者詳細
    const rightsHolderRefModalDiv = ref({})
    let rightsHolderRefModal = null
    const refRightsHolderId = ref('')
    // 利用者詳細
    const userRefModalDiv = ref({})
    let userRefModal = null
    const refUserId = ref('')
    // メソッド
    const openArtistRefModal = async (artistId) => {
      refArtistId.value = artistId
      artistRefModal.show()
    }
    const closeArtistRefModal = () => {
      refArtistId.value = null
      artistRefModal.hide()
    }
    const gotoArtist = (id) => {
      artistRefModal.hide()
      router.push('/common/artist/disp/' + id)
    }
    const openMasterRefModal = async (masterId) => {
      refMasterId.value = masterId
      masterRefModal.show()
    }
    const closeMasterRefModal = () => {
      masterRefModal.hide()
    }
    const openAlbumRefModal = async (albumId) => {
      refAlbumId.value = albumId
      albumRefModal.show()
    }
    const closeAlbumRefModal = () => {
      albumRefModal.hide()
    }
    const gotoAlbum = (albumId) => {
      albumRefModal.hide()
      router.push('/red/album/disp/' + albumId)
    }
    const gotoMaster = (masterId) => {
      masterRefModal.hide()
      router.push('/red/master/disp/' + masterId)
    }
    const openContractRefModal = async (contractId) => {
      refContractId.value = contractId
      contractRefModal.show()
    }
    const closeContractRefModal = () => {
      refContractId.value = null
      contractRefModal.hide()
    }
    const gotoContract = (id) => {
      contractRefModal.hide()
      router.push('/red/contract/disp/' + id)
    }
    const openRightsHolderRefModal = async (rightsHolderId) => {
      refRightsHolderId.value = rightsHolderId
      rightsHolderRefModal.show()
    }
    const closeRightsHolderRefModal = () => {
      refRightsHolderId.value = null
      rightsHolderRefModal.hide()
    }
    const gotoRightsHolder = (id) => {
      rightsHolderRefModal.hide()
      router.push('/red/rightsholder/disp/' + id)
    }
    const openUserRefModal = async (userId) => {
      refUserId.value = userId
      userRefModal.show()
    }
    const closeUserRefModal = () => {
      refUserId.value = null
      userRefModal.hide()
    }
    const gotoUser = (id) => {
      userRefModal.hide()
      router.push('/red/user/disp/' + id)
    }
    onMounted(async () => {
      artistRefModal = new Modal(artistRefModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      masterRefModal = new Modal(masterRefModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      albumRefModal = new Modal(albumRefModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      contractRefModal = new Modal(contractRefModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      rightsHolderRefModal = new Modal(rightsHolderRefModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      userRefModal = new Modal(userRefModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      form.value = await resultFunc.get(route.params.id)
      console.log(form.value)
    })
    return {
      router,
      form,
      rightAreaMode,
      openArtistRefModal,
      closeArtistRefModal,
      gotoArtist,
      artistRefModalDiv,
      refArtistId,
      masterRefModalDiv,
      albumRefModalDiv,
      openAlbumRefModal,
      closeAlbumRefModal,
      openMasterRefModal,
      closeMasterRefModal,
      refAlbumId,
      refMasterId,
      gotoAlbum,
      gotoMaster,
      openContractRefModal,
      closeContractRefModal,
      gotoContract,
      contractRefModalDiv,
      refContractId,
      openRightsHolderRefModal,
      closeRightsHolderRefModal,
      gotoRightsHolder,
      rightsHolderRefModalDiv,
      refRightsHolderId,
      openUserRefModal,
      closeUserRefModal,
      gotoUser,
      userRefModalDiv,
      refUserId
    }
  }
})
</script>
