<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-3 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-newspaper fa-fw" style="margin-right: 5px;"></i> 支払調書一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="openDataModal" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-file fa-fw" style="margin-right: 5px;"></i>データ</button>
            </div>
          </div>
          <div class="input-group" style="margin-top: 15px;">
            <input v-model="store.year" @change="store.page = 0; find();" type="number" class="form-control" style="flex: 0 1 90px;">
            <span class="input-group-text">年</span>
            <input @keydown.enter="store.page = 0; find();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件">
            <select v-model="store.taxationType" @change="store.page = 0; find();" class="form-select" style="flex: 0 1 250px;">
              <option value="all">全て</option>
              <option value="personal_under">課税（個人）5万円未満</option>
              <option value="personal_over">課税（個人）5万円以上</option>
              <option value="none_under">非課税5万円未満</option>
              <option value="none_over">非課税5万円以上</option>
              <option value="fix_under">課税（その他）5万円未満</option>
              <option value="fix_over">課税（その他）5万円以上</option>
            </select>
            <select v-model="store.sortColumn" @change="store.page = 0; find();" class="form-select d-none d-lg-block" style="flex: 0 1 150px;">
              <option value="name">名前</option>
              <option value="code">コード</option>
            </select>
            <select v-model="store.sortOrder" @change="store.page = 0; find();" class="form-select d-none d-lg-block" style="flex: 0 1 160px;">
              <option value="asc">昇順（A → Z）</option>
              <option value="desc">降順（Z → A）</option>
            </select>
            <button @click="clearSearch" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
            <button @click="store.page = 0; find();" class="btn btn-primary"><i class="fas fa-search"></i></button>
          </div>
        </div>
        <div style="position: fixed; top: 110px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <table v-if="paymentRecordResult && paymentRecordResult.allRecords > 0" class="table table-responsive table-hover" style="font-size: 90%;">
            <thead>
              <tr>
                <th style="width: 55px;"></th>
                <th style="width: 100px;">課税区分</th>
                <th style="width: 120px;">権利者コード</th>
                <th>権利者</th>
                <th style="width: 90px;">税抜支払額</th>
                <th style="width: 90px;">源泉徴収額</th>
                <th style="width: 90px;">消費税額</th>
                <th style="width: 90px;">税込支払額</th>
              </tr>
            </thead>
            <tbody v-if="paymentRecordList">
              <tr v-for="payment in paymentRecordList" :key="payment.id" class="text-start">
                <td><router-link :to="'/red/paymentrecord/disp/' + payment.rightsHolderId + '/' + payment.withholdingTaxation + '/' + store.year" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link></td>
                <td>
                  <span v-if="payment.withholdingTaxation === 'personal'">個人</span>
                  <span v-else-if="payment.withholdingTaxation === 'none'">非課税</span>
                  <span v-else>{{payment.withholdingRate}}%</span>
                </td>
                <td>{{ payment.rightsHolderCode }}</td>
                <td>{{ payment.rightsHolderName }}</td>
                <td class="text-end">{{(payment.paymentAmount + payment.withholdingTaxAmount - payment.taxAmount).toLocaleString()}}</td>
                <td class="text-end">{{payment.withholdingTaxAmount.toLocaleString()}}</td>
                <td class="text-end">{{payment.taxAmount.toLocaleString()}}</td>
                <td class="text-end">{{payment.paymentAmount.toLocaleString()}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="paymentRecordResult && paymentRecordResult.allRecords > 0">{{ paymentRecordResult.allRecords }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="paymentRecordResult && paymentRecordResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="paymentRecordResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{ store.page + 1 }}/{{ paymentRecordResult.allPages }}ページ</span></li>
                <li v-if="store.page !== paymentRecordResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== paymentRecordResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="dataModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">データダウンロード</h5>
          </div>
          <div class="modal-body text-start">
<!--            <div class="form-label align-bottom">抽出条件</div>-->
<!--            <div class="form-edit-line">-->
<!--              <select v-model="downloadForm.findTarget" class="form-select">-->
<!--                <option value="all">全て</option>-->
<!--                <option value="personal_under_5">課税（個人）5万円未満</option>-->
<!--                <option value="personal_over_5">課税（個人）5万円以上</option>-->
<!--                <option value="exemption_under_5">非課税5万円未満</option>-->
<!--                <option value="exemption_over_5">非課税5万円以上</option>-->
<!--                <option value="other_under_5">課税（その他）5万円未満</option>-->
<!--                <option value="other_over_5">課税（その他）5万円以上</option>-->
<!--              </select>-->
<!--            </div>-->
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>対象データ</div>
            <div class="form-edit-line">
              <div class="form-check form-check-inline">
                <input v-model="downloadForm.dataType" class="form-check-input" type="radio" name="dataType" id="dataTypeList" value="list">
                <label class="form-check-label" for="dataTypeList">調書一覧表</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="downloadForm.dataType" class="form-check-input" type="radio" name="dataType" id="dataTypeData" value="data">
                <label class="form-check-label" for="dataTypeData">提出用調書データ</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="downloadForm.dataType" class="form-check-input" type="radio" name="dataType" id="dataTypePdf" value="pdf">
                <label class="form-check-label" for="dataTypePdf">調書PDF（3枚）</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="downloadForm.dataType" class="form-check-input" type="radio" name="dataType" id="dataTypePdf2" value="pdf2">
                <label class="form-check-label" for="dataTypePdf2">調書PDF（1枚）</label>
              </div>
            </div>
<!--            <div v-if="downloadForm.dataType === 'list'" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>データフォーマット</div>-->
<!--            <div v-if="downloadForm.dataType === 'list'" class="form-edit-line">-->
<!--              <div class="form-check form-check-inline">-->
<!--                <input v-model="downloadForm.fileType" class="form-check-input" type="radio" name="fileType" id="fileTypeXlsx" value="xlsx">-->
<!--                <label class="form-check-label" for="fileTypeXlsx">Excelファイル</label>-->
<!--              </div>-->
<!--              <div class="form-check form-check-inline">-->
<!--                <input v-model="downloadForm.fileType" class="form-check-input" type="radio" name="fileType" id="fileTypeTsv" value="tsv">-->
<!--                <label class="form-check-label" for="fileTypeTsv">タブ区切りテキスト</label>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div v-if="downloadForm.fileType === 'tsv'" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>文字コード</div>-->
<!--            <div v-if="downloadForm.fileType === 'tsv'" class="form-edit-line">-->
<!--              <div class="form-check form-check-inline">-->
<!--                <input v-model="downloadForm.fileEncoding" class="form-check-input" type="radio" name="encoding" id="encodingSjis" value="sjis">-->
<!--                <label class="form-check-label" for="encodingSjis">Shift_JIS</label>-->
<!--              </div>-->
<!--              <div class="form-check form-check-inline">-->
<!--                <input v-model="downloadForm.fileEncoding" class="form-check-input" type="radio" name="encoding" id="encodingUtf8" value="utf8">-->
<!--                <label class="form-check-label" for="encodingUtf8">UTF-8</label>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div v-if="downloadForm.fileType === 'tsv'" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>改行コード</div>-->
<!--            <div v-if="downloadForm.fileType === 'tsv'" class="form-edit-line">-->
<!--              <div class="form-check form-check-inline">-->
<!--                <input v-model="downloadForm.returnCode" class="form-check-input" type="radio" name="returnCode" id="returnCodeWin" value="windows">-->
<!--                <label class="form-check-label" for="returnCodeWin">Windows形式（CRLF）</label>-->
<!--              </div>-->
<!--              <div class="form-check form-check-inline">-->
<!--                <input v-model="downloadForm.returnCode" class="form-check-input" type="radio" name="returnCode" id="returnCodeUnix" value="unix">-->
<!--                <label class="form-check-label" for="returnCodeUnix">Unix形式（LF）</label>-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button @click="downloadStart" class="btn btn-primary"><i class="fas fa-file-download" style="margin-right: 5px;"></i>ダウンロード開始</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="loadingModalDiv">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">読み込み中</h5>
          </div>
          <div class="modal-body text-center">
            <i class="fas fa-spinner fa-spin"></i>
            Loading
          </div>
        </div>
      </div>
    </div>
    <div class="toast position-fixed top-0 end-0 bg-danger" role="alert" aria-live="assertive" aria-atomic="true" ref="errorToastDiv" style="margin-top: 5px; margin-right: 5px; z-index: 1090;">
      <div class="toast-header">
        <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
        <strong class="me-auto fs-6">エラー</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body text-white fs-6 text-start">
        <p style="margin-bottom: 0;" v-for="(error, index) in errors" :key="index">{{ error }}</p>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Modal } from 'bootstrap'
import { paymentRecordStore } from './store'
import { paymentRecordFunction } from './function'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    const loadingModalDiv = ref(null)
    let loadingModal = null
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    const errors = ref([])
    const errorToastDiv = ref(null)
    const dataModalDiv = ref(null)
    let dataModal = null
    // 検索
    const store = paymentRecordStore()
    const paymentRecordFunc = paymentRecordFunction()
    const paymentRecordResult = ref({})
    const paymentRecordList = ref([])
    // ダウンロード
    const downloadForm = ref({
      findTarget: 'all',
      dataType: 'list'
    })
    // メソッド
    const find = async () => {
      loadingModal.show()
      paymentRecordResult.value = await paymentRecordFunc.find(session.session.company.id)
      paymentRecordList.value = paymentRecordResult.value.list
      console.log(paymentRecordList.value)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      loadingModal.hide()
    }
    const clearSearch = () => {
      paymentRecordFunc.clear(store)
      store.year = new Date().getFullYear() - 1
      find()
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = paymentRecordResult.value.allPages - 1
      find()
    }
    const openDataModal = () => {
      dataModal.show()
    }
    const downloadStart = async () => {
      errors.value = []
      processMessage.value = 'ダウンロードファイルの作成中です。ダウンロードが開始するまでこのままでお待ちください。'
      processModal.show()
      dataModal.hide()
      const url = await paymentRecordFunc.download(session.session.company.id, downloadForm.value.dataType)
      console.log(url)
      const a = document.createElement('a')
      a.href = url
      a.click()
      processModal.hide()
    }
    onMounted(async () => {
      loadingModal = new Modal(loadingModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      dataModal = new Modal(dataModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      store.year = new Date().getFullYear() - 1
      loadingModal.show()
      session.session = await auth.getSession()
      await find()
      loadingModal.hide()
    })
    return {
      router,
      loadingModalDiv,
      processMessage,
      processModalDiv,
      errors,
      errorToastDiv,
      dataModalDiv,
      store,
      paymentRecordResult,
      paymentRecordList,
      downloadForm,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
      openDataModal,
      downloadStart
    }
  }
})
</script>
