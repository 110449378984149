import { defineStore } from 'pinia'
import { ref } from 'vue'

export const paymentRecordStore = defineStore('paymentrecord', () => {
  const year = ref(0)
  const searchString = ref('')
  const taxationType = ref('all')
  const sortColumn = ref('name')
  const sortOrder = ref('asc')
  const page = ref(0)
  return { year, searchString, taxationType, sortColumn, sortOrder, page }
})
