import axios from 'axios'
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'
import { paymentRecordStore } from './store'

export const paymentRecordFunction = () => {
  const auth = authFunction()
  const ex = exFunction()
  const store = paymentRecordStore()
  const findByRightsHolder = async (rightsHolderId, withholdingTaxation, year) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/record/rightsholder/' + rightsHolderId + '/' + year + '/' + withholdingTaxation + '/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const find = async (companyId) => {
    const params = {
      companyId: companyId,
      fiscalYear: store.year,
      searchString: store.searchString,
      sortColumn: store.sortColumn,
      sortOrder: store.sortOrder,
      page: store.page,
      perPage: 20
    }
    if (store.taxationType !== 'all') {
      params.taxationType = store.taxationType
    }
    const { data } = await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/record/list', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: params
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const clear = (store) => {
    store.searchString = ''
    store.findTarget = 'all'
    store.page = 0
  }
  const getAdjust = async (rightsHolderId, year) => {
    const { data } = await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/record/rightsholder/' + rightsHolderId + '/' + year + '/adjust', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  const saveAdjust = async (rightsHolderId, year, data) => {
    await axios.post(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/record/rightsholder/' + rightsHolderId + '/' + year + '/adjust', data, {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
    }).catch(error => {
      ex.identifyEx(error)
    })
  }
  const download = async (companyId, dataType) => {
    const params = {
      dataType: dataType,
      companyId: companyId,
      fiscalYear: store.year,
      searchString: store.searchString,
      sortColumn: store.sortColumn,
      sortOrder: store.sortOrder
    }
    if (store.taxationType !== 'all') {
      params.taxationType = store.taxationType
    }
    const { data } = await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/record/download', {
      headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
      params: params
    }).catch(error => {
      ex.identifyEx(error)
    })
    return data
  }
  return { find, findByRightsHolder, clear, getAdjust, saveAdjust, download }
}
